<template>
  <div>
    <v-card elevation="4">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h5 font-weight-medium">
          Riscos
        </div>
        <HelpDialog
          class="mx-4 flex-grow-1"
          title="Riscos de Tratamento de Dados"
          message="Preciso de ajuda para cadastrar os riscos de tratamento de dados."
        >
          <p>
            Para proteção judicial da empresa, é importante que o titular esteja ciente e aceite os riscos do tratamento de dados.
            No <b>aplicativo</b>, esses riscos serão bastante destacados, de maneira que o titular visualize-os claramente antes de aceitar um consentimento.
          </p>
          <p>
            Futuramente, o registro dos riscos será de grande ajuda para a montagem do relatório de impacto, que será solicitado pela ANPD (Autoridade Nacional de Proteção de Dados).
          </p>
          <v-expansion-panels class="my-2" ripple>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey lighten-2">
                Trechos da LGPD relativos ao Relatório de Impacto
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4">
                <p>...</p>
                <p>
                  <b>Art. 5 - inciso XVII</b>: relatório de impacto à proteção de dados pessoais: documentação do controlador que contém a descrição dos processos de tratamento de dados pessoais que podem gerar riscos às liberdades civis e aos direitos fundamentais, bem como medidas, salvaguardas e mecanismos de mitigação de risco;
                </p>
                <p>...</p>
                <p>
                  <b>Art. 10 - § 3º</b>: A autoridade nacional poderá solicitar ao controlador relatório de impacto à proteção de dados pessoais, quando o tratamento tiver como fundamento seu interesse legítimo, observados os segredos comercial e industrial.
                </p>
                <p>...</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </HelpDialog>
        <v-btn color="success" @click="openRiskDialog()">
          Novo Risco
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-subtitle class="py-2">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details="auto"
          class="mt-0 pt-0"
        />
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="risks"
        :items-per-page="-1"
        :search="search"
        :loading="loading"
        loading-text="Carregando... Por favor, espere"
        hide-default-footer
        no-data-text="Nenhum Risco cadastrado"
      >
        
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="openRiskDialog(item)">
            <v-icon class="mr-2"> mdi-pencil </v-icon>
          </v-btn>
          <v-btn icon @click="deleteDialog = true; risk_id = item.id">
            <v-icon class="mr-2"> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>
    <v-dialog v-model="riskDialog" max-width="800" :persistent="loadingSave">
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <div v-if="riskForm.id">
            Edição de Risco
          </div>
          <div v-else>
            Novo Risco
          </div>
          <div>
            <v-btn icon @click="riskDialog = false">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
        <v-form ref="form" @submit.prevent="saveRisk()">
          <v-text-field
            v-model="riskForm.title"
            label="Titulo *"
            outlined
            hide-details="auto"
            color="black"
            background-color="white"
            :rules="[$rules.required]"
            :disabled="loadingSave"
          />
            <v-textarea
              v-model="riskForm.description"
              label="Descrição *"
              outlined
              hide-details="auto"
              color="black"
              class="my-3"
              background-color="white"
              :rules="[$rules.required]"
              :disabled="loadingSave"
            />
          <v-card-actions class="pt-3 pb-0 pr-0">
            <v-spacer/>
            <v-btn
              color="secondary"
              class="mr-1" text
              @click="riskDialog = false"
              :disabled="loadingSave"
            >
              Cancelar
            </v-btn>
            <v-btn color="success" type="submit" :loading="loadingSave">
              <template v-if="riskForm.id">
                Salvar
              </template>
              <template v-else>
                Criar
              </template>
            </v-btn>
          </v-card-actions>
        </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="500" :persistent="loadingDelete">
      <v-card color="grey lighten-2">
        <v-card-title class="text-subtitle-1 d-flex justify-center">
          Tem certeza que deseja excluir esse Risco?  
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
            <v-btn color="secondary" class="mr-1" text :disabled="loadingDelete"  @click="deleteDialog = false">Cancelar</v-btn>
            <v-btn class="ml-1" :loading="loadingDelete" color="error" text @click="deleteRisk()"
              >Excluir</v-btn
            >
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import HelpDialog from "@/components/HelpDialog.vue";

export default {
  name: "Risks",

  components: { HelpDialog, },

  data: () => ({
    search: "",
    loading: false,
    loadingSave: false,
    loadingDelete: false,
    riskDialog: false,
    deleteDialog: false,
    risk_id: -1,
    riskForm: {
      title: "",
      description: "",
    },
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        //filtereable: false,
        value: "title",
      },
      { text: "Descrição", value: "description" },
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    risks: [],
  }),
  mounted() {
    this.getRisks();
  },
  methods: {
    saveRisk(){
      if (this.$refs.form.validate()) {
      this.loadingSave = true;
      let request;
      if(this.riskForm.id){
        request = this.$axios.put(`/risk/${this.riskForm.id}`, this.riskForm)
      }
      else {
        request = this.$axios.post("/risk", this.riskForm)
      }
      request
        .then((response) => {
          this.$showMessage("success", "Risco Salvo");
          this.getRisks();
          this.riskDialog = false;
        })
        .catch((error) => {
          if (error.response) {
            if(error.response.status == 404) {
              this.$showMessage("error", "Risco não encontrado")
              return
            }
          }
          this.$showMessage("error", "Ocorreu um erro inesperado" )
        }) 
        .finally(() => {
          this.loadingSave = false;
        })
      }
    },
    deleteRisk() {
      this.loadingDelete = true;
      this.$axios.delete(`/risk/${this.risk_id}`)
      .then((response) => {
        this.$showMessage("success", "Risco Excluido");
        this.getRisks();
        this.deleteDialog = false
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 404) {
            this.$showMessage("error", "Risco não encontrado")
            return
          }
        }
        this.$showMessage("error", "Ocorreu um erro inesperado")
      })
      .finally(() => {
          this.loadingDelete = false;
      })
    },
    getRisks(){
      this.loading = true;
      this.$axios.get("/risks")
      .then((response) => {
        this.risks = response.data
      })
      .catch((error) => {
        if(error.response) {
          if(error.response.status == 422) {
            this.$showMessage("error", "Não foi possível carregar os Riscos")
          }
        }
        this.$showMessage("error", "Erro inesperado no carregamento dos Riscos")
      })
      .finally(() => {
        this.loading = false;
      })
    },
    /* resetForm(){
      this.$refs.form.reset()
    }, */ 
    openRiskDialog(risk) {
      if(risk){
        this.riskForm = Object.assign({}, risk)
      } else {
        this.riskForm = {}
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      }
      this.riskDialog = true; 
    }
  },
};
</script>
